<template>
  <MainCard>
    <template v-slot:body>
      <v-tabs
        v-model="tab"
        show-arrows
        fixed-tabs
      >
        <v-tab
          v-for="(tabItem,index) in tabs"
          :key="tabItem.icon"
          @click="reRenderComponent(index)"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tabItem.icon }}
          </v-icon>
          <span>{{ tabItem.title }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <violation-student :key="componentKey"></violation-student>
        </v-tab-item>
        <v-tab-item>
          <violation-employee :key="componentKey2"></violation-employee>
        </v-tab-item>
        <v-tab-item>
          <violation-setting :key="componentKey3"></violation-setting>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </MainCard>
</template>

<script>
import MainCard from '@/views/components/MainCard.vue'
import ViolationEmployee from './ViolationEmployee.vue'
import ViolationSetting from './ViolationSetting.vue'
import ViolationStudent from './ViolationStudent.vue'

export default {
  name: 'Violation',
  components: {
    ViolationStudent,
    ViolationEmployee,
    ViolationSetting,
    MainCard,
  },
  data() {
    return {
      tab: '',
      componentKey: 0,
      componentKey2: 0,
      componentKey3: 0,
      tabs: [{ title: 'Siswa' }, { title: 'Pegawai' }, { title: 'Pengaturan' }],
    }
  },
  mounted() {
    this.checkRole()
  },
  methods: {
    checkRole() {
      if (JSON.parse(localStorage.getItem('user')).user.details[0]?.status_work === 'Guru BK') {
        this.tabs = []
        this.tabs = [{ title: 'Siswa' }, { title: 'Pengaturan' }]
      } else {
        this.tabs = []
        this.tabs = [{ title: 'Siswa' }, { title: 'Pegawai' }, { title: 'Pengaturan' }]
      }
    },

    reRenderComponent(index) {
      switch (index) {
        case 0:
          this.componentKey += 1
          break
        case 1:
          this.componentKey2 += 1
          break
        case 2:
          this.componentKey3 += 1
          break
        default:
          break
      }
    },
  },
}
</script>

<style>
</style>
